import React from "react"
import Lottie from "react-lottie"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"

const Wrap = styled.div`
  width: 600px;
  height: ${props => (props.code ? 348 : 426)}px;

  @media only screen and (max-width: 1279px) {
    width: 450px;
    height: ${props => (props.code ? 261 : 319.5)}px;
  }

  @media only screen and (max-width: 767px) {
    width: 320px;
    height: ${props => (props.code ? 185.6 : 227)}px;
  }

  div {
    width: 600px;
    height: ${props => (props.code ? 348 : 426)}px;

    @media only screen and (max-width: 1279px) {
      width: 450px;
      height: ${props => (props.code ? 261 : 319.5)}px;
    }

    @media only screen and (max-width: 767px) {
      width: 320px;
      height: ${props => (props.code ? 185.6 : 227)}px;
    }
  }
`

const JsonLottiePlayer = ({ data, play, code }) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  }

  return (
    <Wrap code={code}>
      <Lottie options={defaultOptions} isStopped={!play} />
    </Wrap>
  )
}

export default JsonLottiePlayer
