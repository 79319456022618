import * as React from "react"
import styled from "styled-components"
import { rem } from "../util/style"
import TabContext from "../context/Tabs"

const TabWrapper = styled.div`
  margin-top: ${rem(58)};
  display: ${props => (props.active ? "flex" : "none")};
  max-width: 100%;
`

const Tab = ({ id, children }) => {
  return (
    <TabContext.Consumer>
      {({ activeTab }) => (
        <TabWrapper active={activeTab === parseInt(id)}>{children}</TabWrapper>
      )}
    </TabContext.Consumer>
  )
}

export default Tab
