import styled from "styled-components"
import { rem } from "../util/style"

const TextPane = styled.div`
  ${props => {
    if (props.alignment) {
      // if (props.alignment === "left") return `padding-right: ${rem(24)};`
      // if (props.alignment === "right") return `padding-left: ${rem(120)};`
      if (props.alignment === "right") return `position: relative; left: 10rem;`
    }
    return `padding-right: ${rem(24)};`
  }}
  max-width: ${rem(700)};

  @media only screen and (max-width: 1699px) {
    left: 0;
    padding: 0 ${rem(24)};
  }

  @media only screen and (max-width: 1279px) {
    padding: 0 ${rem(24)};
  }

  @media only screen and (max-width: 767px) {
    padding: 0;
  }
`

export default TextPane
