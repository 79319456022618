import React, { useEffect } from "react"
import { LocalToastTarget, useLocalToast } from "react-local-toast"
import MailchimpSubscribe from "react-mailchimp-subscribe"

import { FormContainer, TextInput, SubmitButton } from "./style"

const FormUrl =
  "https://guidepad.us14.list-manage.com/subscribe/post?u=94ebc93ec0ce26bbe321afead&amp;id=2ba6675520&amp;f_id=00288be0f0"

const CustomForm = ({ nomb, status, message, onSubmit }) => {
  const { showToast, removeAllToastsByName } = useLocalToast()

  const emailValid = email => {
    return (
      email === undefined ||
      email === "" ||
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    )
  }

  const emailAddressUpdated = e => {
    const email = e.target.value
    const input = e.target

    if (!emailValid(email)) {
      input.style.borderColor = "red"
      input.style.color = "red"
    } else {
      input.style.color = "black"
      input.style.borderColor = "transparent"
    }
  }

  const submitForm = e => {
    e.preventDefault()

    const emailInput = document.getElementById("footerEmail")
    const email = emailInput.value

    if (!emailValid(email)) {
      showToast("footerEmail", "Please provide a valid email address", {
        type: "error",
      })
    } else {
      showToast("footerEmail", "Subscribing...", { type: "loading" })
      onSubmit({
        EMAIL: email,
      })
    }
  }

  useEffect(() => {
    if (status && message) {
      removeAllToastsByName("footerEmail")
      if (status === "success") {
        showToast("footerSubmit", message, { type: "success" })
      } else {
        showToast("footerSubmit", message, { type: "error" })
      }
    }
  }, [status, message])

  return (
    <FormContainer nomb={nomb}>
      <LocalToastTarget name="footerEmail">
        <TextInput
          id="footerEmail"
          type="text"
          placeholder="Enter your email"
          onKeyUp={emailAddressUpdated}
        />
      </LocalToastTarget>
      <LocalToastTarget name="footerSubmit">
        <SubmitButton onClick={submitForm}>Notify Me</SubmitButton>
      </LocalToastTarget>
    </FormContainer>
  )
}

const FooterEmailForm = ({ nomb }) => {
  return (
    <MailchimpSubscribe
      url={FormUrl}
      render={({ subscribe, status, message }) => (
        <CustomForm
          nomb={nomb}
          status={status}
          message={message}
          onSubmit={formData => subscribe(formData)}
        />
      )}
    />
  )
}

export default FooterEmailForm
