import styled from "styled-components"
import { margins, paddings, hasMargins, hasPaddings } from "../util/style"
import { rem } from "../util/style"

const SideBySideLayout = styled.div`
  display: flex;
  margin: ${props => margins(props)};
  padding: ${props => paddings(props)};
  max-height: 100%;

  flex-direction: row;
  align-items: ${props =>
    props.align && props.align === "top" ? "flex-start" : "center"};
  ${props => {
    if (props.alignEdges) return `justify-content: space-between;`
  }}
  ${props => (props.width ? `max-width: ${rem(props.width)};` : ``)}

  ${props => {
    if (!props.nowrap)
      return `@media only screen and (max-width: 767px) {
      flex-direction: ${
        props.ipad && props.ipad.reverse ? "column-reverse" : "column"
      };
    }`
  }}

  @media only screen and (max-width: 1279px) {
    ${props => {
      if (props.ipad) {
        return `
          ${hasMargins(props.ipad) ? `margin: ${margins(props.ipad)};` : ""}
          ${hasPaddings(props.ipad) ? `padding: ${paddings(props.ipad)};` : ""}
          ${
            props.ipad.alignEdges
              ? `justify-content: space-between;`
              : `justify-content: center; align-items: center;`
          }
          ${
            props.ipad.wrap
              ? `
            flex-direction: ${props.ipad.reverse ? "column-reverse" : "column"}
          `
              : ""
          }
          ${
            props.ipad.flexWrap
              ? `flex-wrap: wrap; justify-content: center; align-items: flex-start;`
              : ""
          }
        `
      }
    }}
  }

  @media only screen and (max-width: 767px) {
    ${props => {
      if (props.mobile) {
        return `
          ${hasMargins(props.mobile) ? `margin: ${margins(props.mobile)};` : ""}
          ${
            hasPaddings(props.mobile)
              ? `padding: ${paddings(props.mobile)};`
              : ""
          }
          ${`flex-direction: ${
            props.mobile.reverse ? "column-reverse" : "column"
          }`}
        `
      }
    }}
  }
`

export default SideBySideLayout
