import React, { useState } from "react"
import TabContext from "../context/Tabs"

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <TabContext.Provider
      value={{
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </TabContext.Provider>
  )
}

export default Tabs
