import React from "react"
import { rem } from "../util/style"
import styled from "styled-components"
import Text from "./Text"
import EmailForm from "./Header/FooterEmailForm"

const Container = styled.div`
  padding: 0 ${rem(24)};
  width: 100%;
  position: relative;
`

const ContentWrap = styled.div`
  padding: ${rem(32)};
  margin: 0 auto;
  margin-bottom: ${rem(72)};
  max-width: ${rem(1634)};
  border-radius: ${rem(16)};
  background: rgba(16, 33, 71, 0.6);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const FormContainer = styled.div`
  position: relative;
  margin-left: ${rem(50)};

  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 0;
    margin-top: ${rem(24)};
  }
`

const PrefooterCTA = () => {
  return (
    <Container>
      <ContentWrap>
        <Text size="prefoot" color="#39DEC6">
          Join our waitlist
        </Text>
        <FormContainer>
          <EmailForm nomb />
        </FormContainer>
      </ContentWrap>
    </Container>
  )
}

export default PrefooterCTA
