import * as React from "react"

import Seo from "../components/Seo"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Text from "../components/Text"
import Badge from "../components/Badge"
import TextPane from "../components/TextPane"
import Column from "../components/Column"
import CenteredLayout from "../components/CenteredLayout"
import SideBySideLayout from "../components/SideBySideLayout"
import Tab from "../components/Tab"
import Tabs from "../components/Tabs"
import TabNav from "../components/TabNav"
import Card from "../components/Card"

import GlobeImg from "../svgs/globe.svg"
import CubeIllustration from "../svgs/cube.svg"
import EmbeddedIllustration from "../svgs/embedded-workflow.svg"

import DynamicTypesImg from "../svgs/dynamic-types.svg"
import SoftwareAsDataImg from "../svgs/software-as-data.svg"
import ApisImg from "../svgs/apis.svg"
import AutomationImg from "../svgs/automation.svg"
import CommunicationImg from "../svgs/communication.svg"
import ExtensibilityImg from "../svgs/extensibility.svg"
import Group40025 from "../svgs/Group40025.svg"

import PlanForChangeIcon from "../svgs/plan-for-change-icon.svg"
import FunctionalPanesIcon from "../svgs/functional-panes-of-glass.svg"
import AutomateAwayIcon from "../svgs/automate-away-icon.svg"

import {
  ContentWrap,
  List,
  ListItem,
  CodeViewIllustrationOffset,
  WorkflowOffset,
  GlobeOffset,
  GradientWrap,
  GradientBox,
  BlurElipse,
  TabContentWrap,
  TabContent,
  TabContentLeft,
  TabImageWrap,
  Colors,
  LimitWidth,
  BodyWrap,
  EfficiencyIllustrationOffset,
} from "../style"
import PrefooterCTA from "../components/PrefooterCTA"

const IndexPage = () => (
  <>
    <BodyWrap>
      <Header />
      <CenteredLayout
        px="48"
        py="137"
        mobile={{ align: "left", px: "24", py: "150" }}
      >
        <Text size="h2" mb="24" color="#C4FFF7" width="927" align="center">
          Efficiently develop, specify, deploy and maintain complex systems at
          scale
        </Text>
      </CenteredLayout>

      <CenteredLayout>
        <ContentWrap>
          <SideBySideLayout
            mb="150"
            ipad={{ mb: "64" }}
            mobile={{ reverse: true }}
          >
            <Column>
              <CenteredLayout>
                <GlobeOffset>
                  <GlobeImg />
                </GlobeOffset>
              </CenteredLayout>
            </Column>
            <Column>
              <TextPane alignment="right">
                <Badge>Build and deploy systems anywhere</Badge>
                <Text size="h2" my="24">
                  Manage environmental complexity
                </Text>
                <List>
                  <ListItem>
                    <Text>
                      Build and deploy systems in the cloud, on prem or on the
                      edge
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      Abstract away differences between environments into
                      reusable control planes that standardize system control
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      Convert complex environments into
                      configurable/interchangeable resources
                    </Text>
                  </ListItem>
                </List>
              </TextPane>
            </Column>
          </SideBySideLayout>

          <SideBySideLayout
            mb="200"
            ipad={{ mb: "100" }}
            mobile={{ mb: "150" }}
          >
            <Column elevate>
              <TextPane>
                <Badge>Operations Definition</Badge>
                <Text size="h2" my="24">
                  Encapsulate functionality
                </Text>
                <List>
                  <ListItem>
                    <Text>
                      Define reusable application logic as operation data
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      Dynamically route operation requests to the services that
                      provide them
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      Automatically generate APIs (REST, GraphQL, etc.) to
                      expose that logic
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>Natively import and use operations in your code</Text>
                  </ListItem>
                </List>
              </TextPane>
            </Column>
            <Column>
              <CenteredLayout>
                <CodeViewIllustrationOffset>
                  <BlurElipse />
                  {/* <StaticImage
                    src="../images/ecapsulation-of-functionality.png"
                    placeholder="blurred"
                    layout="constrained"
                    width={659}
                    height={521}
                    objectFit="cover"
                    style={{
                      zIndex: 5,
                      top: "-3rem",
                      position: "relative",
                      maxWidth: "100%",
                    }}
                    imgStyle={{ maxWidth: "100%" }}
                  /> */}
                  {/* <EncapsulationImg
                    style={{ zIndex: 5, position: "relative" }}
                  /> */}
                  <Group40025 />
                </CodeViewIllustrationOffset>
              </CenteredLayout>
            </Column>
          </SideBySideLayout>

          <SideBySideLayout
            mb="100"
            ipad={{ mb: "0" }}
            mobile={{ mb: "150", reverse: true }}
          >
            <Column>
              <CenteredLayout>
                <EfficiencyIllustrationOffset>
                  <CubeIllustration />
                </EfficiencyIllustrationOffset>
              </CenteredLayout>
            </Column>
            <Column elevate>
              <TextPane alignment="right" nopad>
                <Badge>Define your types as data</Badge>
                <Text size="h2" my="24">
                  Generate system types at runtime
                </Text>
                <List>
                  <ListItem>
                    <Text>
                      Define types as language-agnostic data, rather than in
                      code
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      Dynamically map and migrate types between storage engines
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      Rapidly respond to change by enforcing schemas and
                      generating type code at runtime
                    </Text>
                  </ListItem>
                </List>
              </TextPane>
            </Column>
          </SideBySideLayout>

          <SideBySideLayout
            mb="195"
            ipad={{ mb: "150" }}
            // mobile={{ reverse: true }}
          >
            <Column elevate>
              <TextPane>
                <Badge>Embedded Workflow Automation</Badge>
                <Text size="h2" my="24">
                  Automate on top of abstractions
                </Text>

                <List>
                  <ListItem>
                    <Text>
                      Break out of DAG files by defining your workflows as data
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      Stop repeating yourself — reuse Guidepad abstractions
                      (operations, environments, etc.) within your workflows
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>Automate away repetitive tasks</Text>
                  </ListItem>
                </List>
              </TextPane>
            </Column>
            <Column>
              <CenteredLayout>
                <WorkflowOffset>
                  <BlurElipse top="120" right="175" mobile={{ hide: true }} />
                  <EmbeddedIllustration />
                </WorkflowOffset>
              </CenteredLayout>
            </Column>
          </SideBySideLayout>
        </ContentWrap>

        <GradientWrap>
          <GradientBox />
          <CenteredLayout mb="160">
            <ContentWrap>
              <CenteredLayout px="24" pt="139" mobile={{ pt: "100", px: "0" }}>
                <Badge>The Solution</Badge>
                <Text size="h2" width="1170" align="center" mt="32" mb="54">
                  Overcome complexity and build for change by defining your
                  systems as data within Guidepad
                </Text>
                <Tabs>
                  <TabNav
                    labels={[
                      "Dynamic Types",
                      "Software as Data",
                      "APIs",
                      "Automation",
                      "Communication",
                      "Extensibility",
                    ]}
                  />
                  <Tab id="0">
                    <TabContentWrap>
                      <TabContent>
                        <SideBySideLayout align="top" alignEdges>
                          <Column>
                            <TabContentLeft pt="100">
                              <LimitWidth width="552">
                                <Text size="h3" color={Colors.green} upcase>
                                  Dynamic Types
                                </Text>
                                <Text size="h4" mt="16" mb="24">
                                  Define Types as data and let Guidepad generate
                                  code objects that you can modify if needed
                                </Text>
                                <Text>
                                  Guidepad's Type system provides a
                                  language-agnostic framework for declaring
                                  schema and then generates relevant code
                                  objects at runtime.
                                </Text>
                              </LimitWidth>
                            </TabContentLeft>
                          </Column>
                          <Column fitContent>
                            <TabImageWrap>
                              <DynamicTypesImg />
                            </TabImageWrap>
                          </Column>
                        </SideBySideLayout>
                      </TabContent>
                    </TabContentWrap>
                  </Tab>
                  <Tab id="1">
                    <TabContentWrap>
                      <TabContent>
                        <SideBySideLayout align="top" alignEdges>
                          <Column>
                            <TabContentLeft pt="60">
                              <LimitWidth width="552">
                                <Text size="h3" color={Colors.green} upcase>
                                  Software as Data
                                </Text>
                                <Text size="h4" mt="16" mb="24">
                                  Declare your Services and Environments, manage
                                  their states with State Plans
                                </Text>
                                <Text>
                                  Guidepad allows users to declare services and
                                  environments as data, and create new custom
                                  definitions to capture configurations specific
                                  to your system components.
                                </Text>
                              </LimitWidth>
                            </TabContentLeft>
                          </Column>
                          <Column fitContent>
                            <TabImageWrap>
                              <SoftwareAsDataImg />
                            </TabImageWrap>
                          </Column>
                        </SideBySideLayout>
                      </TabContent>
                    </TabContentWrap>
                  </Tab>
                  <Tab id="2">
                    <TabContentWrap>
                      <TabContent>
                        <SideBySideLayout align="top" alignEdges>
                          <Column>
                            <TabContentLeft>
                              <LimitWidth width="598">
                                <Text size="h3" color={Colors.green} upcase>
                                  APIs
                                </Text>
                                <Text size="h4" mt="16" mb="24">
                                  Design functionality with Operations and let
                                  Guidepad generate your APIs
                                </Text>
                                <Text>
                                  The Operation Type and its sub-types allow
                                  users to define system functionality as data.
                                </Text>
                              </LimitWidth>
                            </TabContentLeft>
                          </Column>
                          <Column fitContent>
                            <TabImageWrap>
                              <ApisImg />
                            </TabImageWrap>
                          </Column>
                        </SideBySideLayout>
                      </TabContent>
                    </TabContentWrap>
                  </Tab>
                  <Tab id="3">
                    <TabContentWrap>
                      <TabContent>
                        <SideBySideLayout align="top" alignEdges>
                          <Column>
                            <TabContentLeft pt="50">
                              <LimitWidth width="552">
                                <Text size="h3" color={Colors.green} upcase>
                                  Automation
                                </Text>
                                <Text size="h4" mt="16" mb="24">
                                  Break free of DAG files with WorkPlans defined
                                  as data
                                </Text>
                                <Text>
                                  Existing workflow automation platforms confine
                                  users to files, often requiring changes to
                                  many lines of code and version control commits
                                  to modify flow, behavior, or triggers.
                                  Guidepad shifts this paradigm by defining the
                                  entirety of a workflow on top of its
                                  generative Type system.
                                </Text>
                              </LimitWidth>
                            </TabContentLeft>
                          </Column>
                          <Column fitContent>
                            <TabImageWrap>
                              <AutomationImg />
                            </TabImageWrap>
                          </Column>
                        </SideBySideLayout>
                      </TabContent>
                    </TabContentWrap>
                  </Tab>
                  <Tab id="4">
                    <TabContentWrap>
                      <TabContent>
                        <SideBySideLayout align="top" alignEdges>
                          <Column>
                            <TabContentLeft>
                              <LimitWidth width="583">
                                <Text size="h3" color={Colors.green} upcase>
                                  Communication
                                </Text>
                                <Text size="h4" mt="16" mb="24">
                                  Simplify Inter-Service messaging with a
                                  dedicated delivery backbone
                                </Text>
                                <Text>
                                  Guidepad provides a native communication
                                  backbone for Inter-Service communication,
                                  removing the need for developers to directly
                                  wire Services together.
                                </Text>
                              </LimitWidth>
                            </TabContentLeft>
                          </Column>
                          <Column fitContent>
                            <TabImageWrap>
                              <CommunicationImg />
                            </TabImageWrap>
                          </Column>
                        </SideBySideLayout>
                      </TabContent>
                    </TabContentWrap>
                  </Tab>
                  <Tab id="5">
                    <TabContentWrap>
                      <TabContent>
                        <SideBySideLayout align="top" alignEdges>
                          <Column>
                            <TabContentLeft pt="80">
                              <LimitWidth width="552">
                                <Text size="h3" color={Colors.green} upcase>
                                  Extensibility
                                </Text>
                                <Text size="h4" mt="16" mb="24">
                                  Layer on functionality via Plugins to get
                                  things just right
                                </Text>
                                <Text>
                                  Guidepad's Plugin framework allows end-users
                                  to extend existing functionality and provide
                                  capabilities not present within the core
                                  system. Extend Datastore classes to provide
                                  transparent data storage in a cutting-edge
                                  storage engine. Define custom Types and UI
                                  views for your client.
                                </Text>
                              </LimitWidth>
                            </TabContentLeft>
                          </Column>
                          <Column fitContent>
                            <TabImageWrap>
                              <ExtensibilityImg />
                            </TabImageWrap>
                          </Column>
                        </SideBySideLayout>
                      </TabContent>
                    </TabContentWrap>
                  </Tab>
                </Tabs>
              </CenteredLayout>
            </ContentWrap>
          </CenteredLayout>

          <CenteredLayout mb="138" mobile={{ mb: "100", px: "24" }}>
            <Text size="h2" width="828" align="center">
              Move beyond Infrastructure-as-Code to Platform-as-Data
            </Text>
          </CenteredLayout>
        </GradientWrap>
      </CenteredLayout>

      <CenteredLayout>
        <ContentWrap width="1650">
          <SideBySideLayout
            mb="159"
            align="top"
            ipad={{ wrap: true, px: 48 }}
            alignEdges
          >
            <Card
              icon={<PlanForChangeIcon />}
              title={
                <>
                  Plan for
                  <br />
                  Change
                </>
              }
              content="The software development ecosystem is always in a state of flux, and the rate of change is always accelerating as new tools and technologies enter and leave the market. Guidepad provides a stable set of concepts and abstractions that you and your team can count on no matter where the industry takes you."
              cta="Notify Me"
              action={() => {
                alert("Notify me!")
              }}
            />

            <Card
              icon={<FunctionalPanesIcon />}
              title="Functional Panes of Glass"
              content="Guidepad's Platform-as-Data approach gives you the observability of other “panes of glass” platforms for free, as your entire system is declared as data within the framework. Guidepad extends the traditional “pane of glass” by attaching functionality to the data definitions via the Type system."
              cta="Notify Me"
              action={() => {
                alert("Notify me!")
              }}
            />

            <Card
              icon={<AutomateAwayIcon />}
              title="Automate Away Tedious Tasks"
              content="As you and your team discover and standardize repeatable tasks as Operations, Guidepad’s WorkPlan system provides the capability to quickly automate and chain together those Operations. Unlike other workflow management systems, the WorkPlan system integrates natively with the rest of GuidePad, allowing for the automation of operations such as schema changes and State Plan executions in response to real-world events."
              cta="Notify Me"
              action={() => {
                alert("Notify me!")
              }}
            />
          </SideBySideLayout>
        </ContentWrap>
      </CenteredLayout>

      <PrefooterCTA />
      <Footer />
    </BodyWrap>
  </>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Platform-as-Data" />

export default IndexPage
