import loadable from "@loadable/component"
import React from "react"
import styled from "styled-components"
import JsonLottiePlayer from "./JsonLottiePlayer"

const RoundWrap = styled.div`
  position: relative;
  margin: 0 1rem;
  border-radius: 16px;
  overflow: hidden;
  height: ${props => (props.code ? 348 : 426)}px;

  ${props => (props.push ? `top: 8rem;` : ``)}

  @media only screen and (max-width: 1279px) {
    width: 450px;
    height: ${props => (props.code ? 261 : 319.5)}px;
  }

  @media only screen and (max-width: 1023px) {
    top: 0;
    margin: 0 auto;
    margin-top: 1.5rem;
  }

  @media only screen and (max-width: 767px) {
    width: 320px;
    height: ${props => (props.code ? 185.6 : 227)}px;
  }
`

const Animation = ({ anim, data, play, push, placeholder }) => {
  return (
    <RoundWrap push={push} code={anim.includes("code")}>
      <JsonLottiePlayer play={play} data={data} code={anim.includes("code")} />
    </RoundWrap>
  )
}

export default Animation
