import * as React from "react"
import styled from "styled-components"
import { rem } from "../util/style"
import Text from "./Text"

const CardWrap = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: ${rem(434)};
  margin: 0 1rem;

  @media only screen and (max-width: 1279px) {
    flex: 0 0 auto;
    margin: 3rem 0;
    max-width: 837px;
  }
`
const ActionWrap = styled.div`
  display: block;
`

const Button = styled.button`
  color: #39dec6;
  font-size: ${rem(16)};
  font-family: Inter, sans-serif;
  font-weight: 500;
  line-height: ${rem(24)};

  padding: ${rem(13)} ${rem(26)};
  display: inline-block;
  background: transparent;
  border-radius: ${rem(6)};
  border: 1px solid #39dec6;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
  transition: all 0.25s linear;

  &:hover {
    color: white;
    background: #39dec6;
  }

  cursor: pointer;
`

const Card = ({ icon, title, content, cta, action }) => {
  return (
    <CardWrap>
      {icon}
      <Text
        size="h2"
        color="#CAF2FF"
        mt={icon ? "26" : undefined}
        mb="31"
        width="400"
        light
        ipad={{ width: true }}
      >
        {title}
      </Text>
      <Text mb={cta && cta !== "" ? "79" : undefined}>{content}</Text>
      {/* <ActionWrap>
        {cta && (
          <>
            <Button onClick={action}>{cta}</Button>
          </>
        )}
      </ActionWrap> */}
    </CardWrap>
  )
}

export default Card
