import * as React from "react"
import styled from "styled-components"
import { rem } from "../util/style"

import Text from "./Text"

const BadgeWrap = styled.div`
  display: inline-block;
  padding: ${rem(8)} ${rem(24)};
  border-radius: ${rem(100)};
  background-color: #cafff7;
`

const Badge = ({ children }) => (
  <BadgeWrap>
    <Text size="h5" color="#005F51" upcase>
      {children}
    </Text>
  </BadgeWrap>
)

export default Badge
