import styled from "styled-components"
import { margins, paddings } from "../util/style"

const Column = styled.div`
  padding: ${props => paddings(props)};
  margin: ${props => margins(props)};
  flex: ${props => (props.fitContent ? "0 0 auto" : "0 0 50%")};
  position: relative;
  ${props => (props.elevate ? `z-index: 10;` : ``)};
`

export default Column
