import * as React from "react"
import styled from "styled-components"
import { rem } from "../util/style"
import { useMediaQuery } from "react-responsive"

import Slider from "react-slick"
import Text from "./Text"
import TabContext from "../context/Tabs"
import Chevron from "../svgs/chevron.svg"

const Button = styled.button`
  flex: 0 0 auto;
  margin: ${rem(16)} ${rem(16)};
  padding: ${rem(8)} ${rem(28)};
  border-radius: ${rem(100)};
  border: none;
  background-color: ${props => (props.active ? "#041377" : "transparent")};
  transition: all 0.25s linear;
  cursor: pointer;

  @media only screen and (max-width: 1279px) {
    margin: ${rem(16)} ${rem(8)};
  }

  @media only screen and (max-width: 767px) {
    width: auto;
    display: inline-block;
  }
`

const HorizontalBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const SliderContainer = styled.div`
  padding-left: 3rem;
  width: 100%;
  display: block;
  position: relative;
`

const ArrowWrap = styled.button`
  display: flex;
  transform-origin: center;
  ${props => (props.prev ? "" : "padding-left: 1rem;")};
  ${props => (props.prev ? "transform: scaleX(-1);" : "")}
  ${props =>
    props.prev ? "left: -3rem; justify-content: flex-start;" : "right: 0;"}

  top: 26%;
  z-index: 10;
  position: absolute;

  background: ${props =>
    props.prev
      ? "#06122c"
      : " linear-gradient(90deg, rgba(6,18,44,0) 0%, rgba(6,18,44,1) 29%, rgba(6,18,44,1) 100%)"};
  border: none;
  outline: none;
`

const PrevArrow = ({ onClick }) => (
  <ArrowWrap onClick={onClick} prev>
    <Chevron />
  </ArrowWrap>
)

const NextArrow = ({ onClick }) => (
  <ArrowWrap onClick={onClick}>
    <Chevron />
  </ArrowWrap>
)

class TabNavSlider extends React.Component {
  render() {
    const sliderProps = {
      dots: false,
      infinite: true,
      variableWidth: true,
      speed: 500,
      slidesToShow: 1.5,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: true,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
    }

    return (
      <TabContext.Consumer>
        {({ activeTab, setActiveTab }) => (
          <SliderContainer>
            <Slider
              ref={slider => (this.slider = slider)}
              {...sliderProps}
              beforeChange={(old, index) => {
                setActiveTab(index)
              }}
            >
              {this.props.labels.map((label, index) => {
                return (
                  <Button
                    key={label}
                    active={index === activeTab}
                    onClick={() => {
                      setActiveTab(index)
                      this.slider.slickGoTo(index)
                    }}
                  >
                    <Text size="nav" color="#9EBCF8" upcase>
                      {label}
                    </Text>
                  </Button>
                )
              })}
            </Slider>
          </SliderContainer>
        )}
      </TabContext.Consumer>
    )
  }
}

const TabNav = ({ labels }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" })

  return isMobile ? (
    <TabNavSlider labels={labels} />
  ) : (
    <>
      <TabContext.Consumer>
        {({ activeTab, setActiveTab }) => (
          <HorizontalBar>
            {labels.map((label, index) => {
              return (
                <Button
                  key={label}
                  active={index === activeTab}
                  onClick={() => setActiveTab(index)}
                >
                  <Text size="nav" color="#9EBCF8" upcase>
                    {label}
                  </Text>
                </Button>
              )
            })}
          </HorizontalBar>
        )}
      </TabContext.Consumer>
    </>
  )
}

export default TabNav
