import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Colors } from "../../style"
import { useMediaQuery } from "react-responsive"

import Badge from "../Badge"
import Text from "../Text"
import Nav from "../Navigation"
import CenteredLayout from "../CenteredLayout"
import BgImage from "../../images/header-background.png"
import Tabs from "../Tabs"
import Tab from "../Tab"
import TabNav from "../TabNav"
import TabContext from "../../context/Tabs"
import EmailForm from "./EmailForm"
import Animation from "../Animation"

import { AnimWrap, BackgroundImage, Elevate, TabsContainer } from "./style"
import { HeaderContainer, ContentContainer } from "./style"

import * as CreateEnvironmentUI from "../../anims/create-environment-ui.json"
import * as CreateEnvironmentCode from "../../anims/create-environment-code.json"
import * as CreateServiceUI from "../../anims/create-service-ui.json"
import * as CreateServiceCode from "../../anims/create-service-code.json"
import * as ApplyStatePlanUI from "../../anims/apply-state-plan-ui.json"
import * as ApplyStatePlanCode from "../../anims/apply-state-plan-code.json"
import * as CreateServiceExposureUI from "../../anims/create-service-exposure-ui.json"
import * as CreateServiceExposureCode from "../../anims/create-service-exposure-code.json"

const Content = {
  h1: "The Development Framework for managing complexity and expanding capabilities",
  h2: "Join our waitlist",
  inputPlaceholder: "Enter your email",
  submit: "Notify Me",
}

const Animations = [
  {
    id: "create-environment",
    ui: CreateEnvironmentUI,
    code: CreateEnvironmentCode,
  },
  {
    id: "create-service",
    ui: CreateServiceUI,
    code: CreateServiceCode,
  },
  {
    id: "apply-state-plan",
    ui: ApplyStatePlanUI,
    code: ApplyStatePlanCode,
  },
  {
    id: "create-service-exposure",
    ui: CreateServiceExposureUI,
    code: CreateServiceExposureCode,
  },
]

const Header = () => {
  const isTablet = useMediaQuery({ query: "(max-width: 1500px)" })
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" })

  const desktopStyles = {
    left: 0,
    right: 0,
    bottom: -150,
    position: "absolute",
  }

  const mediumStyles = {
    left: -200,
    right: -200,
    bottom: -100,
    position: "absolute",
  }
  const smallStyles = {}

  let bgStyle
  if (isTablet) bgStyle = mediumStyles
  else if (isMobile) bgStyle = mediumStyles
  else bgStyle = desktopStyles

  return (
    <HeaderContainer>
      {/* <Toaster /> */}
      <Nav mb="96" />
      <StaticImage
        style={bgStyle}
        src="../../images/glow.png"
        layout="fullWidth"
        placeholder="none"
      />
      <BackgroundImage src={BgImage} />
      <Elevate>
        <CenteredLayout>
          <ContentContainer>
            <Badge>PLATFORM-AS-DATA</Badge>
            <Text size="h1" align="center" mt="32" mb="18">
              {Content.h1}
            </Text>
            <Text
              size="h5"
              color={Colors.green}
              align="center"
              mt="32"
              mb="16"
              mobile={{ px: "36" }}
              upcase
            >
              {Content.h2}
            </Text>
            <EmailForm />
          </ContentContainer>
        </CenteredLayout>
        <TabsContainer>
          <Tabs>
            <TabNav
              labels={[
                "Create Environment",
                "Create Service",
                "Apply State Plan",
                "Create Service Exposure",
              ]}
            />
            {Animations.map((anim, index) => (
              <Tab id={index} key={index}>
                <TabContext.Consumer>
                  {({ activeTab }) => (
                    <AnimWrap>
                      <Animation
                        anim={anim.id}
                        data={anim.ui}
                        play={activeTab === index}
                        placeholder={
                          <StaticImage
                            src="../../images/create-environment.png"
                            alt="Create Environment"
                            placeholder="blurred"
                            layout="constrained"
                            width={600}
                          />
                        }
                      />
                      <Animation
                        anim={`${anim.id}-code`}
                        data={anim.code}
                        play={activeTab === index}
                        push
                      />
                    </AnimWrap>
                  )}
                </TabContext.Consumer>
              </Tab>
            ))}
          </Tabs>
        </TabsContainer>
      </Elevate>
    </HeaderContainer>
  )
}

export default Header
